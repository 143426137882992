import { computed, inject } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { MsalService } from '@azure/msal-angular';
import { patchState, signalStore, withComputed, withMethods, withProps, withState } from '@ngrx/signals';
import { UserSecurity } from 'consistent-api-nvx-internal-sdk-dev';

import { UserContactPerson, UserService } from '@/shared/lib/services/user.service';
import { UserProfile } from '@/shared/lib/types/user.type';
import { LoggerService } from '@/shared/services';

type UserState = {
  isLoading: boolean;
  currentUser: UserSecurity | null;
  userProfile: UserProfile | null;
  contactPerson: UserContactPerson;
};

const initialState: UserState = {
  isLoading: false,
  currentUser: null,
  userProfile: null,
  contactPerson: { type: 'not loaded' },
};

export const UserStore = signalStore(
  { providedIn: 'root', protectedState: true },
  withState(initialState),
  withProps(() => ({
    authService: inject(MsalService),
    userService: inject(UserService),
    logger: inject(LoggerService),
  })),
  withComputed(({ authService, ...store }) => {
    return {
      tenants: computed(() => store.currentUser()?.tenants),
      firstName: computed(() => {
        return store.userProfile()?.firstname ?? authService.instance.getActiveAccount()?.name ?? '';
      }),
      isAdmin: computed(() => store.currentUser()?.applicationPermissions.includes('admin') ?? false),
    };
  }),
  withMethods(({ userService, logger, ...store }) => ({
    async loadCurrentUser() {
      await userService
        .getCurrentUser()
        .then((userRes) => {
          patchState(store, { currentUser: userRes });
        })
        .catch((error) => {
          logger.error('Error fetching Current User', error);
        });
    },
    async loadMyUserProfile() {
      await userService
        .getMyUserProfile()
        .then((userProfileRes) => {
          if (userProfileRes && userProfileRes.items?.length) {
            patchState(store, { userProfile: userProfileRes.items[0] });
          }
        })
        .catch((error) => {
          logger.error('Error fetching My User Profile', error);
        });
    },
    async updateMyUserProfile(userProfile: UserProfile) {
      await userService.updateMyProfile(userProfile).then(() => {
        patchState(store, { userProfile });
      });
    },
    setLoadingState(loadingState: boolean) {
      patchState(store, { isLoading: loadingState });
    },
    resetState() {
      patchState(store, { ...initialState });
    },
  })),
  withStorageSync({
    key: 'user-state',
    storage: () => sessionStorage,
  })
);
